import ActionCell, {
  ReactTableCell,
} from "~/components/table/cells/actionCell";
import { Box, Tooltip } from "@material-ui/core";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import React, { ReactChild, memo, useCallback, useMemo, useState } from "react";
import {
  SalesByProduct,
  useSalesByProductQuery,
} from "../../store/mystore/salesByProduct.redux";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  intFormatterRounded,
} from "~/utils/currencyUtils";

import ACOSCell from "~/components/table/cells/acosCell";
import { AvailabilityCell } from "~/components/table/cells/availabilityCell";
import BannerWithLink from "~/components/alert/bannerWithLink";
import { CategoryCell } from "~/components/table/cells/categoryCell";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { DaysCoverCell } from "~/components/table/cells/daysCoverCell";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import PrimeIcon from "~/img/amazon_prime.png";
import { Range } from "~/typedef/store";
import SalesByProductTableChart from "./salesByProductTableChart";
import { ShowChart } from "@material-ui/icons";
import StatusCell from "../buybox/statusCell";
import TACOSCell from "~/components/table/cells/tacosCell";
import { TYPE } from "../overview/salesByProduct";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { TextCell } from "~/components/table/cells/textCell";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

export const SmallIcon = styled.img`
  position: absolute;
  bottom: 2px;
  left: 32px;
  height: 18px;
`;

export const StyledTableCell = styled.td`
  max-width: 0px;
  overflow-x: visible;
  position: sticky;
  z-index: 3;
  left: 0px;
  padding-left: 16px;
`;

export const InlineBlockDiv = styled.div`
  display: inline-block;
`;

const PAGE_SIZE = 25;
const MAX_DAYS = 90;
const CONDENSED_ROWS = 5;
const DEFAULT_DAYS_COVER_LIMIT = 60;
const MARKETPLACES_WITH_PRODUCT_ADS = ["amazon", "walmart", "bol"];

interface SalesByProductProps {
  mid: string;
  marketplace: string;
  marketplaceSubtype: string;
  sellerId?: string;
  countryCode: string;
  type: keyof typeof TYPE;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  actions?: ReactChild;
  condensed?: boolean;
  report?: boolean;
  searchText?: string;
  includeNoSales: boolean;
  includeNoInventory: boolean;
  pageSize?: number;
  groupId?: string | null;
  timezone?: string;
  conditionalFormatting?: boolean;
  includeTax: boolean;
}

interface FormattedSalesByProductRows
  extends Pick<
    SalesByProduct,
    | "acos"
    | "acos"
    | "tacos"
    | "lastSold"
    | "quantity"
    | "status"
    | "brand"
    | "productId"
    | "pageImpressions"
    | "clickThroughRate"
    | "pageViews"
  > {
  title: {
    value: string;
    secondRowValue: string;
    link: string;
    image: string;
    target: string;
  };
  price: string;
  salesValue: number;
  totalSales: {
    value: string;
    growth: string;
    conditionalFormatting?: boolean;
  };
  unitsSold: {
    value: number;
    growth: string;
    conditionalFormatting?: boolean;
  };
  avPrice: {
    value: string;
    growth: string;
    conditionalFormatting?: boolean;
  };
  adSales: string;
  adSpend: string;
  noQuantitySinceTime: string | number;
  shippingPrice: string;
  fulfillment_channel: string;
  salesRank: {
    value: string | number;
    growth: string | number;
    conditionalFormatting?: boolean;
    reverseFormatting: boolean;
    customSuffix: string;
  };
  category: {
    current: string;
    parent: string | null;
  };
  daysCover: number | null;
}

const SalesByProductTable = memo<SalesByProductProps>(
  function SalesByProductTable({
    mid,
    marketplace,
    marketplaceSubtype,
    type,
    condensed,
    sellerId,
    countryCode,
    currentPeriod,
    currentRange,
    currentCurrency,
    footerLink,
    actions,
    searchText,
    includeNoSales,
    includeNoInventory,
    report,
    pageSize,
    groupId,
    timezone,
    conditionalFormatting,
    includeTax,
  }) {
    const { t } = useTranslation();
    const isVendorStore = useVendorStore(mid);
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const daysCoverLimit =
      useTypedSelector(
        (state) => state.notifications.daysCoverLimits.data
      )?.find((d) => d.mid === mid)?.daysCoverLimit ?? DEFAULT_DAYS_COVER_LIMIT;

    const contentRef = React.useRef<HTMLHeadingElement>(null);

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize: pageSize ?? PAGE_SIZE,
      pageIndex: 0,
      sortKey: TYPE[type],
      sortOrder: !["worstSellers", "topBSR"].includes(type) ? "desc" : "asc",
    });

    const { salesByProductRows, salesByProductCount, salesByProductFetching } =
      useSalesByProductQuery(
        {
          type,
          mid,
          marketplace,
          marketplaceSubtype,
          sellerId,
          countryCode,
          currentRange,
          searchText,
          includeNoSales,
          includeNoInventory,
          includeTax,
          groupId,
          ...paginationParams,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            salesByProductRows: data?.rows || [],
            salesByProductCount: data?.count || 0,
            salesByProductFetching: isFetching,
          }),
        }
      );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy[0]?.id || TYPE[type],
        sortOrder:
          sortBy && sortBy.length
            ? sortBy[0].desc
              ? "desc"
              : "asc"
            : !["worstSellers", "topBSR"].includes(type)
            ? "desc"
            : "asc",
        pageIndex,
        pageSize,
      });
    }, []);

    const renderSalesByProductSubComponent = ({ row }: any) => {
      const tableRenderedWidth = Number(contentRef.current?.clientWidth) - 20;
      return (
        // eslint-disable-next-line no-magic-numbers
        <tr>
          <StyledTableCell>
            <div
              style={{
                width: tableRenderedWidth,
              }}
            >
              <Box>
                <SalesByProductTableChart
                  mid={mid}
                  currentRange={currentRange}
                  currentPeriod={currentPeriod}
                  currentCurrency={currentCurrency}
                  productId={row.original?.productId}
                  currencyRates={currencyRates}
                  timezone={timezone}
                />
              </Box>
            </div>
          </StyledTableCell>
        </tr>
      );
    };

    const data = useMemo<FormattedSalesByProductRows[]>(
      () =>
        salesByProductRows
          ? salesByProductRows.map((productSales) => {
              const { current, prior } = productSales;
              const sourceCurrency =
                getCurrencyByCountryCode[countryCode] || "AUD";

              const salesRankGrowth =
                current?.salesRank && prior?.salesRank
                  ? Number(current?.salesRank) - Number(prior?.salesRank)
                  : 0;

              return {
                title: {
                  value: current.title,
                  secondRowValue: isVendorStore
                    ? `ASIN: ${current.productSku} | VPI: ${current.sku}`
                    : `${marketplace === "amazon" ? "ASIN" : "LISTING ID"}: ${
                        current.productSku
                      }   |    SKU: ${current.sku}`,
                  link: current.linkUrl,
                  image: current.imageUrl,
                  target: "_blank",
                },
                price: formatCurrency(
                  parseFloat(current.price),
                  currencyRates,
                  sourceCurrency,
                  currentCurrency
                ),
                salesValue: parseFloat(current.totalSales),
                totalSales: {
                  value: formatCurrency(
                    parseFloat(current.totalSales),
                    currencyRates,
                    sourceCurrency,
                    currentCurrency
                  ),
                  growth: prior
                    ? getPercentageDifference(
                        current.totalSales,
                        prior.totalSales
                      )
                    : "N/A",
                  conditionalFormatting: conditionalFormatting,
                },
                unitsSold: {
                  value: current.unitsSold,
                  growth: prior
                    ? getPercentageDifference(
                        current.unitsSold,
                        prior.unitsSold
                      )
                    : "N/A",
                  conditionalFormatting: conditionalFormatting,
                },
                avPrice: {
                  value: formatCurrency(
                    parseFloat(current.totalSales) / current.unitsSold,
                    currencyRates,
                    sourceCurrency,
                    currentCurrency
                  ),
                  growth: prior
                    ? getPercentageDifference(
                        parseFloat(current.totalSales) / current.unitsSold,
                        parseFloat(prior.totalSales) / prior.unitsSold
                      )
                    : "N/A",
                  conditionalFormatting: conditionalFormatting,
                },
                adSales: formatCurrency(
                  current.adSales,
                  currencyRates,
                  sourceCurrency,
                  currentCurrency
                ),
                adSpend: formatCurrency(
                  current.adSpend,
                  currencyRates,
                  sourceCurrency,
                  currentCurrency
                ),
                acos: current.acos,
                tacos: current.tacos,
                lastSold: current.lastSold,
                noQuantitySinceTime:
                  current.noQuantitySinceTime &&
                  moment().diff(moment(current.noQuantitySinceTime), "days")
                    ? moment().diff(moment(current.noQuantitySinceTime), "days")
                    : "-",
                shippingPrice: current.shippingPrice
                  ? formatCurrency(
                      parseFloat(current.shippingPrice),
                      currencyRates,
                      sourceCurrency,
                      currentCurrency
                    )
                  : "Free",
                quantity: current.quantity,
                fulfillment_channel: current.fulfillmentChannel,
                status: current.status,
                salesRank: {
                  value: current?.salesRank || "-",
                  growth:
                    isNaN(salesRankGrowth) || salesRankGrowth === 0
                      ? "-"
                      : salesRankGrowth,
                  conditionalFormatting,
                  reverseFormatting: true,
                  customSuffix: "",
                },
                pageImpressions: current.pageImpressions,
                clickThroughRate: current.clickThroughRate,
                pageViews: current.pageViews,
                brand: current.brand,
                category: {
                  current: current.categoryLabel || t("generic.notSpecified"),
                  parent: current.parentCategoryLabel,
                },
                productId: current.productId,
                daysCover:
                  current.daysCover !== null
                    ? Number(current.daysCover)
                    : current.daysCover,
              };
            })
          : [],
      [t, salesByProductRows, currencyRates, currentCurrency]
    );

    const columns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.salesByProduct.productColumn"),
          id: "title",
          accessor: (row: FormattedSalesByProductRows) => ({
            value: row.title.value,
            secondRowValue: row.title.secondRowValue,
            image: row.title.image,
            link: row.title.link,
            target: "_blank",
            icon: row.status?.isFulfilledByAmazon ? (
              <Tooltip title={t("generic.fulfilledByAmazon") as string}>
                <SmallIcon src={PrimeIcon} />
              </Tooltip>
            ) : undefined,
          }),
          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
              icon?: ReactChild;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isVisible: true,
          sticky: "left",
        },
        {
          Header: t("trafficAndConversion.actionsColumn"),
          accessor: () => {},
          id: "actions",
          Cell: (cell: ReactTableCell) => {
            const actions = [
              {
                icon: ShowChart,
                text: cell.row.isExpanded
                  ? t("generic.hide")
                  : t("generic.show"),
                action: () => {
                  const isExpanded = cell.row.isExpanded;
                  cell.toggleAllRowsExpanded(false);
                  const { onClick } = cell.row.getToggleRowExpandedProps();
                  if (!isExpanded) onClick();
                },
              },
            ];
            return <ActionCell actions={actions} />;
          },
          isVisible: true,
          disableSortBy: true,
        },
        {
          id: "brand",
          Header: t("myStoresWidget.salesByProduct.brandColumn"),
          accessor: "brand",
          Cell: TextCell,
          isVisible: true,
          align: "left",
        },
        {
          id: "categoryLabel",
          Header: t("myStoresWidget.salesByProduct.categoryColumn"),
          accessor: "category",
          Cell: (props: TableCellProp<string>) => <CategoryCell {...props} />,
          align: "left",
          isVisible: true,
        },
        {
          id: "price",
          Header: t("myStoresWidget.salesByProduct.priceColumn"),
          accessor: "price",
          Cell: ValueCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t(
            `myStoresWidget.${
              isVendorStore ? "poProduct" : "salesByProduct"
            }.salesColumn`
          ),
          accessor: "totalSales",
          id: "totalSales",
          align: "right",
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: t(
            isVendorStore
              ? "purchaseOrder.unitsColumn"
              : "myStoresWidget.salesByProduct.unitsColumn"
          ),
          accessor: "unitsSold",
          id: "unitsSold",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.salesByProduct.avgPriceColumn"),
          accessor: "avPrice",
          id: "avPrice",
          Cell: ValueAndGrowthCell,
          align: "right",
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t(
            isVendorStore
              ? "purchaseOrder.lastSoldColumn"
              : "myStoresWidget.salesByProduct.lastSoldColumn"
          ),
          wrapHeaderText: true,
          id: "lastSold",
          accessor: (row: FormattedSalesByProductRows): string | number => {
            if (row.lastSold === "never") {
              return "Unsold";
            }
            const days = moment().diff(moment(row.lastSold), "days");
            return days <= MAX_DAYS ? days : `${MAX_DAYS}+`;
          },
          align: "right",
          Cell: ValueCell,
          isVisible: true,
          sortDescFirst: type !== "worstSellers",
        },
        ...(MARKETPLACES_WITH_PRODUCT_ADS.includes(marketplace) || isVendorStore
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.salesColumn"),
                id: "adSales",
                accessor: "adSales",
                align: "right",
                Cell: ValueCell,
                disableSortBy: true,
              },
              {
                Header: t("customGroups.adSpendColumn"),
                id: "adSpend",
                accessor: "adSpend",
                align: "right",
                Cell: ValueCell,
                disableSortBy: true,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.acosColumn"),
                id: "acos",
                accessor: (row: FormattedSalesByProductRows) => {
                  return {
                    value: row.acos ? `${row.acos.toFixed(1)}%` : "-",
                  };
                },
                align: "right",
                Cell: ACOSCell,
                disableSortBy: true,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.tacosColumn"),
                id: "tacos",
                accessor: (row: FormattedSalesByProductRows) => {
                  return {
                    value: row.tacos ? `${row.tacos.toFixed(1)}%` : "-",
                  };
                },
                align: "center",
                Cell: TACOSCell,
                disableSortBy: true,
              },
            ]
          : []),
        ...(!isVendorStore
          ? [
              {
                Header: t("myStoresWidget.salesByProduct.daysOutOfStockColumn"),
                wrapHeaderText: true,
                id: "noQuantitySinceTime",
                accessor: "noQuantitySinceTime",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
              },
              {
                Header: t("myStoresWidget.salesByProduct.shippingColumn"),
                id: "shippingPrice",
                accessor: "shippingPrice",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
              },
            ]
          : []),
        {
          Header: t("myStoresWidget.salesByProduct.fulfilmentColumn"),
          wrapHeaderText: true,
          id: "fulfillmentChannel",
          accessor: "fulfillment_channel",
          Cell: ValueCell,
          align: "center",
          isVisible: true,
        },
        ...(marketplace === "amazon"
          ? [
              {
                Header: t("myStoresWidget.salesByProduct.buyBoxStatusColumn"),
                wrapHeaderText: true,
                id: "status",
                accessor: "status",
                Cell: StatusCell,
                align: "center",
                disableSortBy: true,
                isVisible: true,
              },
            ]
          : []),
        ...(marketplace === "amazon" && !isVendorStore
          ? [
              {
                Header: t("myStoresWidget.salesByProduct.salesRankColumn"),
                accessor: "salesRank",
                id: "salesRank",
                Cell: ValueAndGrowthCell,
                align: "right",
                isVisible: true,
              },
            ]
          : []),
        ...(marketplace === "ebay"
          ? [
              {
                Header: t("myStoresWidget.salesByProduct.impressionsColumn"),
                id: "pageImpressions",
                accessor: (row: FormattedSalesByProductRows) =>
                  row.pageImpressions
                    ? intFormatterRounded.format(row.pageImpressions)
                    : "-",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
                disableSortBy: true,
              },
              {
                Header: t(
                  "myStoresWidget.salesByProduct.clickThroughRateColumn"
                ),
                id: "clickThroughRate",
                accessor: (row: FormattedSalesByProductRows) =>
                  row.clickThroughRate
                    ? // eslint-disable-next-line no-magic-numbers
                      `${row.clickThroughRate.toFixed(2)}%`
                    : "0.00%",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
                disableSortBy: true,
              },
              {
                Header: t("myStoresWidget.salesByProduct.pageViewsColumn"),
                id: "pageViews",
                accessor: (row: FormattedSalesByProductRows) =>
                  row.pageViews
                    ? intFormatterRounded.format(row.pageViews)
                    : "-",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
                disableSortBy: true,
              },
            ]
          : []),
        ...(!isVendorStore
          ? [
              {
                Header: t("myStoresWidget.salesByProduct.daysCover"),
                accessor: (row: FormattedSalesByProductRows) => ({
                  conditionalFormatting: false,
                  displayValue:
                    row.daysCover !== null &&
                    Number.isFinite(row.daysCover) &&
                    row.daysCover !== 0 &&
                    row.quantity >= 1
                      ? numberWithCommas(row.daysCover.toFixed(1))
                      : "-",
                  compareValue: Number.isFinite(row.daysCover)
                    ? row.daysCover
                    : Number.POSITIVE_INFINITY,
                  lowThreshold: daysCoverLimit / 2,
                  midThreshold: daysCoverLimit,
                  highThreshold: daysCoverLimit * 2,
                }),
                id: "daysCover",
                Cell: DaysCoverCell,
                align: "right",
                isVisible: true,
              },
            ]
          : []),
        ...(!isVendorStore
          ? [
              {
                Header: t(
                  "myStoresWidget.salesByProduct.availableQuantityColumn"
                ),
                id: "quantity",
                accessor: (row: FormattedSalesByProductRows) => ({
                  availableValue: row.quantity,
                  compareValue: row.daysCover,
                  threshold: daysCoverLimit,
                }),
                align: "right",
                Cell: AvailabilityCell,
                isVisible: true,
              },
            ]
          : []),
      ],
      [marketplace]
    );

    const [myColumns, setMyColumns] = useState<Column[]>(columns);

    const condensedColumns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.salesByProduct.productColumn"),
          accessor: "title",
          Cell: (props: any) => (
            <LinkAndImageCell {...props} colorVariant="external" />
          ),
          colSpan: type === "topBSR" ? 2 : 3,
        },
        ...(marketplace === "amazon" && !isVendorStore && type === "topBSR"
          ? [
              {
                Header: t("myStoresWidget.salesByProduct.salesRankColumn"),
                accessor: "salesRank",
                id: "salesRank",
                Cell: ValueAndGrowthCell,
                align: "right",
                hiddenDown: report ? "" : "md",
                customWidth: 100,
              },
            ]
          : []),
        ...(type === "topSellers"
          ? [
              {
                Header: t(
                  `myStoresWidget.${
                    isVendorStore ? "poProduct" : "salesByProduct"
                  }.salesColumn`
                ),
                accessor: "totalSales",
                id: "totalSales",
                align: "right",
                Cell: ValueAndGrowthCell,
                isVisible: true,
              },
              {
                Header: t(
                  isVendorStore
                    ? "purchaseOrder.unitsColumn"
                    : "myStoresWidget.salesByProduct.unitsColumn"
                ),
                accessor: "unitsSold",
                id: "unitsSold",
                Cell: ValueAndGrowthCell,
                align: "right",
                isVisible: true,
              },
            ]
          : marketplace === "amazon" && !isVendorStore && type === "topBSR"
          ? [
              {
                Header: t(
                  `myStoresWidget.${
                    isVendorStore ? "poProduct" : "salesByProduct"
                  }.salesColumn`
                ),
                accessor: "totalSales",
                id: "totalSales",
                align: "right",
                Cell: ValueAndGrowthCell,
                isVisible: true,
              },
            ]
          : [
              {
                Header: t(
                  isVendorStore
                    ? "purchaseOrder.lastSoldColumn"
                    : "myStoresWidget.salesByProduct.lastSoldColumn"
                ),
                wrapHeaderText: true,
                id: "lastSold",
                accessor: (row: FormattedSalesByProductRows) => {
                  if (row.lastSold === "never") {
                    return "Unsold";
                  }
                  const days = moment().diff(moment(row.lastSold), "days");
                  return days <= MAX_DAYS ? days : `${MAX_DAYS}+`;
                },
                align: "right",
                Cell: ValueCell,
                customWidth: report ? "" : 90,
              },
            ]),
        ...(marketplace === "amazon" && type === "worstSellers"
          ? [
              {
                Header: t("myStoresWidget.salesByProduct.buyBoxStatusColumn"),
                id: "status",
                accessor: "status",
                Cell: StatusCell,
                align: "center",
                hiddenDown: report ? "" : "md",
                customWidth: 100,
              },
            ]
          : []),
        ...(marketplace === "ebay" && type === "worstSellers"
          ? [
              {
                Header: t("myStoresWidget.salesByProduct.impressionsColumn"),
                id: "pageImpressions",
                accessor: (row: FormattedSalesByProductRows) =>
                  row.pageImpressions
                    ? intFormatterRounded.format(row.pageImpressions)
                    : "-",
                align: "right",
                Cell: ValueCell,
                hiddenDown: report ? "" : "md",
              },
              {
                Header: t(
                  "myStoresWidget.salesByProduct.clickThroughRateColumn"
                ),
                id: "clickThroughRate",
                accessor: (row: FormattedSalesByProductRows) =>
                  row.clickThroughRate
                    ? // eslint-disable-next-line no-magic-numbers
                      `${row.clickThroughRate.toFixed(2)}%`
                    : "0.00%",
                align: "right",
                Cell: ValueCell,
                hiddenDown: report ? "" : "lg",
              },
            ]
          : []),
        ...(!isVendorStore
          ? [
              {
                Header: t(
                  "myStoresWidget.salesByProduct.availableQuantityColumn"
                ),
                id: "quantity",
                accessor: (row: FormattedSalesByProductRows) => ({
                  availableValue: row.quantity,
                  compareValue: row.daysCover,
                  threshold: daysCoverLimit,
                }),
                align: "right",
                Cell: AvailabilityCell,
                hiddenDown: report
                  ? "md"
                  : type === "worstSellers"
                  ? "sm"
                  : "lg",
              },
            ]
          : []),
      ],
      []
    );

    return (
      <>
        {groupId && (
          <Box pb={2}>
            <BannerWithLink
              type="info"
              isOpen={true}
              message={t("generic.customGroupFilter")}
              link={marketplaceLink(marketplace, mid, "salesbyproduct")}
              linkText={t("generic.viewAllLink")}
            />
          </Box>
        )}
        <Panel
          id="widget-top-sellers"
          title={t(
            isVendorStore
              ? `purchaseOrder.${type}.mainTitle`
              : `myStoresWidget.${type}.mainTitle`
          )}
          tooltip={undefined}
          footerLink={footerLink}
          content={
            condensed ? (
              <>
                <InlineBlockDiv ref={contentRef} />
                <NoScrollTable
                  {...{
                    columns: condensedColumns,
                    data: data,
                    pageSize: pageSize ?? CONDENSED_ROWS,
                    gridLayoutColumns: 6,
                    loading: salesByProductFetching,
                  }}
                />
              </>
            ) : (
              <>
                <InlineBlockDiv ref={contentRef} />
                <Table
                  {...{
                    columns: myColumns,
                    data: data,
                    fetchData,
                    sorting: true,
                    pagination: true,
                    pageSize: pageSize ?? PAGE_SIZE,
                    pageCount: Math.ceil(
                      salesByProductCount / (pageSize ?? PAGE_SIZE)
                    ),
                    loading: salesByProductFetching,
                    renderRowSubComponent: renderSalesByProductSubComponent,
                  }}
                />
              </>
            )
          }
          actions={
            report ? undefined : (
              <>
                {actions}
                <Box pl={2}>
                  <ColumnSelect
                    {...{ columns: myColumns, setColumns: setMyColumns }}
                  />
                </Box>
              </>
            )
          }
        />
      </>
    );
  }
);

export default SalesByProductTable;
